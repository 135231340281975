export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)": [~3,[2]],
		"/(app)/eols": [~4,[2]],
		"/(app)/eols/[slug]": [~5,[2]],
		"/(app)/favorites": [6,[2]],
		"/login": [12],
		"/oauth2/callback": [13],
		"/(app)/pcns": [~7,[2]],
		"/(app)/pcns/[slug]": [~8,[2]],
		"/(app)/products": [~9,[2]],
		"/(app)/products/[slug]": [~10,[2]],
		"/(app)/sales-library": [~11,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';